import React, { Suspense, useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import { adminRoutes, confirmUserRoutes } from './constants/routes'
import apiClient from './shared/services/axios'
import { notification } from 'antd'

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  user
})

const Router = ({ history, routerAnimation, dispatch, user }) => {
  const routes = user.roles?.indexOf('admin') >= 0 ? [...confirmUserRoutes, ...adminRoutes] : confirmUserRoutes

  useEffect(() => {

    apiClient.interceptors.response.use(undefined, error => {

      // Errors handling
      const { response } = error
      const { data } = response

      if (response) {

        const {noToastr} = error.config.headers

        if (error.status === 401 || error.status === 403) {
          dispatch({
            type: 'user/LOGOUT',
          })
        }

        if (noToastr) {
          console.log()
        } else if (typeof data.errors === 'object' && Object.keys(data.errors).length) {
          const validateErrors = [];

          Object.keys(data.errors).forEach(key => {
            if (data.errors[key].length > 0) {
              validateErrors.push(data.errors[key].join(","));
            }
          });

          notification.warning({
            message: "Formda Bazı Hatalar Mevcut",
            description: validateErrors.join(", ")
          })
        } else {
          notification.error({
            message: data?.message || response?.statusText || data,
          })
        }
      }

      return Promise.reject(error.response);
    })
  }, [])

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/product/pending-approval" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation} style={{flex: 1, flexDirection: 'column', display: 'flex'}}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
