import React from 'react'
import { connect } from 'react-redux'
import FavPages from './FavPages'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import Feedback from '../../../../shared/components/codeove/Feedback'
import { Layout, PageHeader } from 'antd'

const mapStateToProps = ({ settings }) => ({
  menuLayoutType: settings.menuLayoutType,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  leftMenuWidth: settings.leftMenuWidth,
})

style.menu = undefined
const TopBar = ({ isMobileMenuOpen, isMobileView, dispatch, isMenuCollapsed }) => {
  const toggleMobileMenu = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const toggleCollapse = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  return (
    <div className={style.topbar}>
      <div>
        <button
          style={{ borderRadius: 3 }}
          type="button"
          className="mr-4 d-flex bg-transparent border-0"
          onClick={isMobileView ? toggleMobileMenu : toggleCollapse}
        >
          <i className="fe fe-menu font-size-36" />
        </button>
      </div>

      <div className="mr-4">{!isMobileView && <FavPages />}</div>

      {isMenuCollapsed && (
        <img className={style.logo} src="resources/images/logo-h45.png" alt="Movell Marketplace - Admin" />
      )}

      <PageHeader
        className="site-page-header dashboard-header mr-auto"
        onBack={() => null}
        title="Movell Yönetim Paneli"
        backIcon={false}
      />

      <div className="mr-4 d-none d-sm-block">
        <Feedback />
      </div>

      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}
export default connect(mapStateToProps)(TopBar)
