import actions from './actions'
import {Action} from "../../shared/models/ReduxModel";

const initialState = {
  authProvider: 'jwt', // firebase, jwt
  logo: 'Movell - Merchant',
  locale: 'tr-TR',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  menuLayoutType: 'left', // left, top, nomenu
  routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
  menuColor: 'white', // white, dark, gray
  theme: 'default', // default, dark
  authPagesColor: 'image', // white, gray, image
  primaryColor: '#3A1078',
  leftMenuWidth: 256,
  isMenuUnfixed: false,
  isMenuShadow: false,
  isTopbarFixed: true,
  isGrayTopbar: false,
  isContentMaxWidth: false,
  isAppMaxWidth: false,
  isGrayBackground: false,
  isCardShadow: true,
  isSquaredBorders: false,
  isBorderless: false,
}

export default function settingsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
