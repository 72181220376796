import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined, MailOutlined } from '@ant-design/icons'
import {Menu, Dropdown, Avatar, Modal, Input, Form, Button} from 'antd'
import store from 'store'
import apiClient from '../../../../../shared/services/axios'
import styles from './style.module.scss'
import {ServiceResult} from "../../../../../shared/models/ServiceResult";
import {MerchantUserModel} from "../../../../../shared/models/MerchantUserModel";

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch }) => {
  const [count, setCount] = useState(7)
  const [profileModalVisible, setProfileModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const userId = store.get('userId')

  useEffect(() => {
    apiClient.get(`Users/${userId}`).then(response => {
      setUserInfo(response.data.result)
    })
  }, [userId])

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#" onClick={e => {e.preventDefault(); setProfileModalVisible(true)}}>
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="/#/update-password">
          <i className="fe fe-lock mr-2" />
          <FormattedMessage id="topBar.profileMenu.updatePassword" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </div>
      </Dropdown>
      <Modal
        footer={false}
        centered
        destroyOnClose
        visible={profileModalVisible}
        className="full-modal"
        width={350}
        title="Profil Bilgileri"
        onCancel={() => setProfileModalVisible(false)}
      >
        <Form
          layout="vertical"
          name="updateProfile"
          initialValues={{
            username: userInfo.name ? userInfo.name : '',
            email: userInfo.email ? userInfo.email : ''
          }}
        >
          <Form.Item
            label="Kullanıcı Adı"
            name="username"
          >
            <Input
              placeholder="Kuallanıcı Adı"
              prefix={<UserOutlined className="site-form-item-icon" />}
              disabled
              className="profile-view-input"
            />
          </Form.Item>

          <Form.Item
            label="E-Posta"
            name="email"
          >
            <Input
              placeholder="E-Posta"
              prefix={<MailOutlined />}
              disabled
              className="profile-view-input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => setProfileModalVisible(false)}
            >
              Kapat
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
