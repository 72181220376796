import {MerchantStatus, Roles} from './enums'
import { Sector } from './SectorModel'

export interface MerchantUser {
  id: string;
  email: string;
  maazaName: string;
  commercialTitle: string;
  slug: string;
  token: string;
  logo: string;
  introImage: string;
  point: number;
  about: string;
  confirmCode: string;
  confirm: boolean;
  status: MerchantStatus;
  sectors: Sector[];
  merchantNewAbout: {
    logoImage: string;
    introImage: string;
    text: string,
    status: MerchantNewAboutStatus,
    deniedMessage: string
  }
  roles: Roles[];
  isCanceled?: boolean;
  isHide?: boolean;
}

export enum MerchantNewAboutStatus {
  Approved = 1,
  Control = 2,
  Denied = 3,
}

export const MerchantNewAboutStatusDesc = {
  [MerchantNewAboutStatus.Approved] : {color : 'green', text: 'Onaylandı'},
  [MerchantNewAboutStatus.Control] : {color : 'blue', text: 'Onay Bekliyor'},
  [MerchantNewAboutStatus.Denied] : {color : 'red', text: 'Reddedildi'},
}
