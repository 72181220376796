import actions from './actions'
import {Action} from "../../shared/models/ReduxModel";

const initialState = {
  id: '',
  name: '',
  roles: [],
  email: '',
  avatar: '',
  active: false,
  authorized: false, // false is default value
  loading: false,
  token: ''
}

export default function userReducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
