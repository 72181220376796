import { lazy } from 'react'

export const adminRoutes = [
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/image-gallery')),
    exact: true,
  },
  {
    path: '/test/nested',
    Component: lazy(() => import('pages/test/NestedForm')),
    exact: true,
  },
  {
    path: '/product/create',
    Component: lazy(() => import('pages/product/ProductCreatePage')),
    exact: true,
  },
  {
    path: '/product/list',
    Component: lazy(() => import('pages/product/ProductList')),
    exact: true,
  },
  {
    path: '/product/merchants',
    Component: lazy(() => import('pages/product/MerchantProductList')),
    exact: true,
  },
  {
    path: '/product/comments',
    Component: lazy(() => import('pages/product/ProductComments')),
    exact: true,
  },
  {
    path: '/image-gallery',
    Component: lazy(() => import('pages/image-gallery')),
    exact: true,
  },
  {
    path: '/brands',
    Component: lazy(() => import('pages/brands')),
    exact: true,
  },
  {
    path: '/bin-numbers',
    Component: lazy(() => import('pages/bin-numbers')),
    exact: true,
  },
  {
    path: '/sectors',
    Component: lazy(() => import('pages/sectors/SectorList')),
    exact: true,
  },
  {
    path: '/examples/datatable',
    Component: lazy(() => import('pages/examples/datatable')),
    exact: true,
  },
  {
    path: '/category',
    Component: lazy(() => import('pages/category/CategoryList')),
    exact: true,
  },
  {
    path: '/approve-about',
    Component: lazy(() => import('pages/approve-about/ApproveAboutList')),
    exact: true,
  },
  {
    path: '/appeal-documents',
    Component: lazy(() => import('pages/appeal-documents/AppealDocumentsList')),
    exact: true,
  },
  {
    path: '/appeals',
    Component: lazy(() => import('pages/appeals/AppealsList')),
    exact: true,
  },
  {
    path: '/feedbacks',
    Component: lazy(() => import('pages/reports/FeedbackList')),
    exact: true,
  },
  {
    path: '/logs',
    Component: lazy(() => import('pages/reports/LogsPage')),
    exact: true,
  },
  {
    path: '/scheduled-tasks',
    Component: lazy(() => import('pages/reports/ScheduledTasks')),
    exact: true,
  },
  {
    path: '/slider',
    Component: lazy(() => import('pages/marketing/slider/SliderList')),
    exact: true,
  },
  {
    path: '/banners',
    Component: lazy(() => import('pages/marketing/banner/BannerList')),
    exact: true,
  },
  {
    path: '/static-pages',
    Component: lazy(() => import('pages/marketing/static-pages/StaticPageList')),
    exact: true,
  },
  {
    path: '/lead-management',
    Component: lazy(() => import('pages/marketing/lead-management/LeadsList')),
    exact: true,
  },
  {
    path: '/order/list',
    Component: lazy(() => import('pages/orders/OrdersList')),
    exact: true,
  },
  {
    path: '/order/contested',
    Component: lazy(() => import('pages/orders/OrdersContested')),
    exact: true,
  },
  {
    path: '/order/payment',
    Component: lazy(() => import('pages/orders/OrdersPayment')),
    exact: true,
  },
  {
    path: '/order/special-refund-requests',
    Component: lazy(() => import('pages/orders/SpecialRefundRequestsList')),
    exact: true,
  },
  {
    path: '/update-password',
    Component: lazy(() => import('pages/update-password')),
    exact: true,
  },
  {
    path: '/tickets',
    Component: lazy(() => import('pages/tickets/MyTickets')),
    exact: true,
  },
  {
    path: '/blog-list',
    Component: lazy(() => import('pages/blog/blog-list')),
    exact: true,
  },
  {
    path: '/super-admin/integration-docs',
    Component: lazy(() => import('pages/super-admin/IntegrationDocs')),
    exact: true,
  },
]

export const confirmUserRoutes = [
  {
    path: '/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password/:guid',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/product/pending-approval',
    Component: lazy(() => import('pages/product/ProductCommitList')),
    exact: true,
  },
  {
    path: '/product/catalog',
    Component: lazy(() => import('pages/product/ProductCatalog')),
    exact: true,
  },
  {
    path: '/super-admin/cargo-prices',
    Component: lazy(() => import('pages/super-admin/CargoPrices')),
    exact: true,
  },
]
