import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isRegisterPage,
}) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
          }}
        >
          <div className={style.topbar}>
            <div className={style.logoContainer}>
              <a href="#">
                <img className={style.logo} src="resources/images/logo-h45.png" alt="Codeove Marketplace - Admin" />
              </a>
            </div>

            <div className="d-none d-sm-block">
              {isRegisterPage ? (
                <>
                  <span className="mr-2">Zaten hesabınız var mı?</span>
                  <Link to="/auth/login" className="font-size-16 kit__utils__link">
                    Giriş Yapın
                  </Link>
                </>
              ) : (
                <>
                </>
              )}
            </div>
          </div>

          <div style={{height: 64, marginBottom: "7vh"}} />

          <div className={isRegisterPage ? style.containerInnerFull : style.containerInner}>
            {children}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
