export const adminMenu = [
  // {
  //   category: true,
  //   title: 'Anasayfa',
  // },
  // {
  //   title: 'Anasayfa',
  //   key: 'dashboards',
  //   icon: 'fe fe-home',
  //   url: '/dashboard/alpha',
  // },
  {
    category: true,
    title: 'İçerik Yönetimi',
  },
  {
    title: 'Ürünler',
    key: 'products',
    icon: 'fe fe-database',
    children: [
      {
        title: 'Ürün Ekle',
        key: 'productCreate',
        url: '/product/create',
      },
      {
        title: 'Ürün Yönetimi',
        key: 'productList',
        url: '/product/list',
      },
      {
        title: 'Mağaza Ürünleri',
        key: 'productMerchantList',
        url: '/product/merchants',
      },
      {
        title: 'Ürün Onaylama',
        key: 'productApprove',
        url: '/product/pending-approval',
      },
      {
        title: 'Ürün Kataloğu',
        key: 'productCatalog',
        url: '/product/catalog',
      },
      {
        title: 'Ürün Yorumları',
        key: 'productComments',
        url: '/product/comments',
      },
    ],
  },
  {
    title: 'Kategoriler',
    key: 'categories',
    icon: 'fe fe-bookmark',
    children: [
      {
        title: 'Kategori Yönetimi',
        key: 'categorymanagxe',
        url: '/category',
      },
    ],
  },
  {
    title: 'Marketing - SEO',
    key: 'marketing',
    icon: 'fe fe-star',
    children: [
      {
        title: 'Sabit Sayfalar',
        key: 'staticPages',
        url: '/static-pages',
      },
      {
        title: 'Slayt Yönetimi',
        key: 'slide-list',
        url: '/slider',
      },
      {
        title: 'Banner Yönetimi',
        key: 'banner-list',
        url: '/banners',
      },
      {
        title: 'Talep Yönetimi',
        key: 'lead-management',
        url: '/lead-management',
      }
    ],
  },
  {
    title: 'Görseller',
    key: 'image-gallery',
    icon: 'fe fe-image',
    url: '/image-gallery',
  },
  {
    title: 'Markalar',
    key: 'brands',
    icon: 'fe fe-pocket',
    url: '/brands',
  },
  {
    title: 'Bin Numaraları',
    key: 'binNumbers',
    icon: 'fe fe-credit-card',
    url: '/bin-numbers',
  },
  {
    title: 'Sektörler',
    key: 'sectors',
    icon: 'fe fe-layers',
    url: '/sectors',
  },
  {
    category: true,
    title: 'Sipariş Yönetimi',
  },
  {
    title: 'Siparişler',
    key: 'orders',
    icon: 'fe fe-shopping-cart',
    url: '/order/list',
  },
  {
    title: 'İtilaflı Siparişler',
    key: 'contested',
    icon: 'fe fe-x-circle',
    url: '/order/contested',
  },
  {
    title: 'Ödeme Takibi',
    key: 'payment',
    icon: 'fe fe-credit-card',
    url: '/order/payment',
  },
  {
    title: 'Özel İade Talepleri',
    key: 'SpecialRefundRequestsList',
    icon: 'fe fe-corner-up-left',
    url: '/order/special-refund-requests',
  },
  {
    category: true,
    title: 'Başvuru Yönetimi',
  },
  {
    title: 'Hakkımızda Onaylama',
    key: 'approveAbout',
    icon: 'fe fe-info',
    url: '/approve-about',
  },
  {
    title: 'Destek Talepleri',
    key: 'myTickets',
    icon: 'fe fe-edit-3',
    url: '/tickets',
  },
  {
    title: 'Başvuru Talepleri',
    key: 'appeal-requests',
    icon: 'fe fe-mail',
    url: '/appeals',
  },
  {
    title: 'Başvuru Evrakları',
    key: 'appeal-documents',
    icon: 'fe fe-file',
    url: '/appeal-documents',
  },
  {
    category: true,
    title: 'Raporlar',
  },
  {
    title: 'Geri Bildirimler',
    key: 'feedbacks',
    icon: 'fe fe-message-circle',
    url: '/feedbacks',
  },
  {
    title: 'Loglar',
    key: 'logs',
    icon: 'fe fe-file-text',
    url: '/logs',
  },
  {
    title: 'Zamanlanmış Görevler',
    key: 'scheduled-tasks',
    icon: 'fe fe-clock',
    url: '/scheduled-tasks',
  },
  // {
  //   category: true,
  //   title: 'For Developers',
  // },
  // {
  //   title: 'Örnekler',
  //   key: 'examples',
  //   icon: 'fe fe-code',
  //   children: [
  //     {
  //       title: 'Datatable',
  //       key: '/examples/datatable',
  //       url: '/examples/datatable',
  //     },
  //   ],
  // },
]

export const superAdminMenu = [
  {
    category: true,
    title: 'Süper Yönetici',
  },
  {
    title: 'Kargo Ücretleri',
    key: 'cargo-prices',
    icon: 'fe fe-truck',
    url: '/super-admin/cargo-prices',
  },
  {
    title: 'Entegrasyon Dökümanları',
    key: 'integration-docs',
    icon: 'fe fe-file',
    url: '/super-admin/integration-docs',
  },
]

export const confirmUserMenu = [
  {
    category: true,
    title: 'İçerik Yönetimi',
  },
  {
    title: 'Ürünler',
    key: 'products',
    icon: 'fe fe-database',
    children: [
      {
        title: 'Ürün Onaylama',
        key: 'productApprove',
        url: '/product/pending-approval',
      },
      {
        title: 'Ürün Kataloğu',
        key: 'productCatalog',
        url: '/product/catalog',
      }
    ]
}]
