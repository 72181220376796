import apiClient from 'shared/services/axios'
import store from 'store'

export async function login(email: string, password: string) {
  return apiClient
    .post('/Users/Login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
        }
        return response.data.result
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email: string, password: string, name: string) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const userId = store.get('userId')
  return apiClient
    .get(`Users/${userId}`)
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
        }
        return response.data.result
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  store.remove('accessToken')
  store.remove('userId')
  return true
}
