import appConfig from '../../../appconfig.json';

export interface ConfigModel {
  apiUrl : string
  websiteUrl: string
  adminUrl: string
  merchantUrl: string
}

const config : ConfigModel = appConfig[process.env.REACT_APP_ENV || 'dev']

export default config
